export const environment = {
    production: true,
	A1:'xz$%23!t3@ibpm',
	TITLE:'Tigo Sports Paraguay',
	WEB_VERSION:'6.0.2',
	COUNTRY:'PY',
	DATA_FACTORY_PATH:'https://estaticopy.tigocloud.net/',
	IMA_ADS_PATH:'https://pubads.g.doubleclick.net/gampad/ads',
	GRANT_TYPE:'client_credentials',
	APPE_ID:'U2FsdGVkX1/NlBAMbsX57I0pA2iu5/R/uWg2IKbvuZA=',
	CREATION_CHANNEL:'tigosports',
	DEEPLINK_HOST:'https://www.tigosports.com.py/dl/',
	DEEPLINK_SCHEMA:'tigo-sports-app://',
	BW_API_PIX:'U2FsdGVkX1+4PeLFmtRLjeGFlL58wEZJsHMXUgzuzkjbGQj0N0qdd5OYWRwrgw0SakB33CcoRGDYoe8XU73CNA==',
	BW_BASE:'U2FsdGVkX1+VN+8KbR7ZrDLj6SLgguranquDQFYCNFFnY25IUXYSonSUExNmxyyh',
	SAFARI_WEBSITE_PUSH_ID:'web.onl.pxt.preprod-ott',
	O5:'!XZ15BHBZ4LEmyz',
	DEEPLINK_IOS_APP:'https://apps.apple.com/app/tigo-sports-paraguay/id963028258',
	PROCESS_PIX:'U2FsdGVkX1/F6j55SYcvhvvJaAwtAzWOjf4hI1FmxMM=',
	PYT_TYPE:'ingenico',
	SEGMENT_SOURCE_NAME:'Tigo Sports-PY',
	FIREBASE_KY:'AIzaSyB4v4pL1BA-0PmJzvWfH9-zN3yzApq_JYc',
	FIREBASE_PDI:'tigo-sports-app',
	FIREBASE_APP:'1:285925071575:web:0aea346d35f69f7ea52e92',
	FIREBASE_MDI:'G-2W4R22ZSMN',
	MIXPANEL_PROXY:'https://analytics.tigocloud.net',
	MIXPANEL_TOKEN:'062de53e335e9a92d7af8ff386b703c3',
	GENERAL_PROJECT:false,
	APP_DEBUG:false,
	MIDDLEWARE_PATH:'https://api-dev.pxt.onl',
	APP_SYNC_PATH:'https://sync.tigosports.com.py/graphql',
	APP_SYNC_PATH_WS:'wss://sync.tigosports.com.py/graphql/realtime',
	ENVIRONMENT_VERSION:'1',
	MIDDLEWARE_VERSION:'v4',
	PPV_URL:'https://pagos.tigosports.com.py',
	PROCESS_VERSION:'1',
	BASE_URL:'https://www.tigosports.com.py',
	PYT_VALUE:'U2FsdGVkX1/DfhV+ti7S885czd6NhFYpL32JFLPImSLzeUjvKdd0v+8Kv6OsG8xlzpKHjiSlliKxXob+UgoJgw==',
	PYT_IV:'U2FsdGVkX1/LhKL+azcryfYnZobPynBjJmJtDZ2BveYyQniYovdPAZwW5zF2bvLf',
	MERCHANT_ID:'millicom_gt_tigosports',
	ORG_ID:'undefined',
}; 

export const MIDDLEWARE_HOST = environment.MIDDLEWARE_PATH + '/api/' + environment.MIDDLEWARE_VERSION + '/';