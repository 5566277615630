export const environment = {
    production: false,
	A1:'xz$%23!t3@ibpm',
	TITLE:'Tigo Sports Paraguay',
	WEB_VERSION:'6.0.2',
	COUNTRY:'PY',
	DATA_FACTORY_PATH:'https://estaticopy.tigocloud.net/',
	IMA_ADS_PATH:'https://pubads.g.doubleclick.net/gampad/ads',
	GRANT_TYPE:'client_credentials',
	APPE_ID:'U2FsdGVkX189ahaUO9Xs/86373qiG7e+CPHGn05Q8/E=',
	CREATION_CHANNEL:'tigosports',
	DEEPLINK_HOST:'https://www.tigosports.com.py/dl/',
	DEEPLINK_SCHEMA:'tigo-sports-app://',
	BW_API_PIX:'U2FsdGVkX1/AUHHdOlU4QcSdICvWuJnAb7OEiEhiOakdHtrqAmh54pmnvJHvw26dLHWju5JCubOevx6LHUsXqw==',
	BW_BASE:'U2FsdGVkX19CjGYZus32rgYKJA0lmkhVRW416ypXmoOi3RefPSodCNblkbgMOKYF',
	SAFARI_WEBSITE_PUSH_ID:'web.onl.pxt.preprod-ott',
	O5:'!XZ15BHBZ4LEmyz',
	DEEPLINK_IOS_APP:'https://apps.apple.com/app/tigo-sports-paraguay/id963028258',
	PROCESS_PIX:'U2FsdGVkX1+xkYevVbpKK9rNXGTLl1PCHOKjiHCrNpk=',
	PYT_TYPE:'ingenico',
	SEGMENT_SOURCE_NAME:'Tigo Sports-PY',
	FIREBASE_KY:'AIzaSyB4v4pL1BA-0PmJzvWfH9-zN3yzApq_JYc',
	FIREBASE_PDI:'tigo-sports-app',
	FIREBASE_APP:'1:285925071575:web:0aea346d35f69f7ea52e92',
	FIREBASE_MDI:'G-2W4R22ZSMN',
	MIXPANEL_PROXY:'https://analytics.tigocloud.net',
	MIXPANEL_TOKEN:'062de53e335e9a92d7af8ff386b703c3',
	GENERAL_PROJECT:false,
	APP_DEBUG:true,
	MIDDLEWARE_PATH:'https://api-dev.pxt.onl',
	MIDDLEWARE_VERSION:'v4',
	PPV_URL:'https://preprod-ppv.pxt.onl',
	PROCESS_VERSION:'0',
	BASE_URL:'https://preprod-ott.pxt.onl',
	PYT_VALUE:'U2FsdGVkX1+5+1jDyaI5BkxEe8BFCMPpBuffjhKJPrPecrOK2qeucZmnnV6zc+Twxw6W9ZIFQJeYJXr8W4GJfA==',
	PYT_IV:'U2FsdGVkX1+RtU23qhLghkEbNLZwWvgBzH2L9udfTbBf1vrP+dXwb+zcOW8xwNRA',
	MERCHANT_ID:'millicom_gt_tigosports',
	ORG_ID:'U2FsdGVkX1+ihGZdME2Z5CH4uvFEHjd6+8eHdmaZDqg=',
	APP_SYNC_PATH:'https://5ecq46ge4zeulixbor7xf7l5su.appsync-api.us-east-1.amazonaws.com/graphql',
	APP_SYNC_PATH_WS:'wss://5ecq46ge4zeulixbor7xf7l5su.appsync-realtime-api.us-east-1.amazonaws.com/graphql',
}; 

export const MIDDLEWARE_HOST = environment.MIDDLEWARE_PATH + '/api/' + environment.MIDDLEWARE_VERSION + '/';